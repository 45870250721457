import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';

import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';

import { SelectOptionValue } from 'types/select';
import { TrackInputFocus } from 'util/google_optimize/optimize_helper';
import Form from 'react-bootstrap/Form';

import { SelectOptionProps, SelectProps } from './select.props';
import './select.style.scss';

const Select = <T extends SelectOptionValue>(props: SelectProps<T>): ReactElement => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState<T | undefined>(props.value);

    const { className, errors, placeholder, onChange, options = [], touched } = props;

    const classes = classNames(
        'select',
        className,
        { 'is-open': isMenuOpen },
        { 'has-value': selectedValue },
        { 'has-errors': errors && touched }
    );

    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        TrackInputFocus(event.target, placeholder);
    };

    const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        const currentTarget = e.currentTarget;

        // Check the newly focused element in the next tick of the event loop
        setTimeout(() => {
            // Check if the new activeElement is a child of the original container
            if (!currentTarget.contains(document.activeElement)) {
                // You can invoke a callback or add custom logic here
                setIsMenuOpen(false);
            }
        }, 0);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        if (target) {
            const selectedOptions = target.selectedOptions;
            if (selectedOptions && selectedOptions[0]) {
                // subtract one, since the select starts with a blank option
                const selectedOption = options[selectedOptions[0].index - 1];
                if (selectedOption) {
                    setSelectedValue(selectedOption.value);
                    if (onChange) onChange({ key: selectedOption.key, option: selectedOption });
                }
            }
        }
    };

    return (
        <div className={classes} onBlur={handleBlur} onFocus={handleOnFocus}>
            <div className="select-display-text">
                <div className="select-placeholder">{placeholder}</div>
            </div>
            <Form.Control as="select" value={selectedValue} onChange={handleChange} className="select-display">
                <option value=""></option>
                {options &&
                    options.map((opt: SelectOptionProps<T>) => (
                        <option key={`select-${opt.value}`} value={opt.value}>
                            {opt.label}
                        </option>
                    ))}
            </Form.Control>
            <ChevronIcon direction="down" />
            {touched && errors && <div className="select-errors">{errors}</div>}
        </div>
    );
};

export default Select;
