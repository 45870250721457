import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { REGISTRATION_SCHEMA } from 'schema/registration.schema';
import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import Tooltip from 'ui-kit/tooltip/tooltip';
import WorkflowSteps from 'ui-kit/workflow-steps/workflow-steps';

import { getDaysOfMonthOptions, getYearOptions, monthOptions } from 'const/options';
import FormSelect from 'ui-kit/form-select/form-select';

// TODO (TECH DEBT): we should move this back into types/registration
export interface RegistrationFormValues {
    firstName: string;
    lastName: string;
    dobMonth: string;
    dobDay: string;
    dobYear: string;
    memberId: string;
}

export interface RegistrationFormProps {
    className?: string;
    onSubmit: (
        values: Partial<RegistrationFormValues>,
        helpers: FormikHelpers<Partial<RegistrationFormValues>>
    ) => void;
}

const RegistrationForm = ({ className, onSubmit }: RegistrationFormProps) => {
    const { t } = useTranslation();

    const classes = classNames(className);
    const minYear = new Date().getFullYear() - 120;
    const yearOptions = useMemo(() => getYearOptions({ min: minYear }), [minYear]);
    const dayOptions = useMemo(() => getDaysOfMonthOptions(), []);
    const formName = 'RegistrationForm';

    function getErrorFromStatus(status: string): String {
        let errorMessage = '';
        switch (status) {
            case 'Patient is already registered.':
                errorMessage = t('registration.errors.alreadyRegistered');
                break;
            default:
                errorMessage = t('registration.errors.notEligable');
                break;
        }
        return errorMessage;
    }

    return (
        <div className={classes}>
            <Row>
                <Col>
                    <Formik
                        onSubmit={onSubmit}
                        validationSchema={REGISTRATION_SCHEMA}
                        initialValues={{
                            firstName: undefined,
                            lastName: undefined,
                            dobMonth: undefined,
                            dobDay: undefined,
                            dobYear: undefined,
                            memberId: undefined
                        }}
                    >
                        {({
                            values,
                            touched,
                            errors,
                            status,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <Form
                                id="registration-form"
                                data-ga-form-name={formName}
                                onSubmit={handleSubmit}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col sm={12} lg={6}>
                                        <Text
                                            name="firstName"
                                            label={t('profile.firstName')}
                                            onChange={handleChange}
                                            errors={errors?.firstName ? t(`profile.errors.firstName`) : undefined}
                                            touched={touched.firstName}
                                            value={values?.firstName}
                                        />
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <Text
                                            name="lastName"
                                            label={t('profile.lastName')}
                                            onChange={handleChange}
                                            errors={errors?.lastName ? t(`profile.errors.lastName`) : undefined}
                                            touched={touched.lastName}
                                            value={values?.lastName}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={4}>
                                        <Field
                                            id="profile-setup-dob-month"
                                            name="dobMonth"
                                            options={monthOptions}
                                            component={FormSelect}
                                            placeholder={t('profile.dobMonth')}
                                            errors={errors?.dobMonth ? t(`profile.errors.dobMonth`) : undefined}
                                            touched={touched.dobMonth}
                                        />
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <Field
                                            id="profile-setup-dob-day"
                                            name="dobDay"
                                            options={dayOptions}
                                            component={FormSelect}
                                            placeholder={t('profile.dobDay')}
                                            errors={errors?.dobDay ? t(`profile.errors.dobDay`) : undefined}
                                            touched={touched.dobDay}
                                        />
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <Field
                                            id="profile-setup-dob-year"
                                            name="dobYear"
                                            options={yearOptions}
                                            component={FormSelect}
                                            placeholder={t('profile.dobYear')}
                                            errors={errors?.dobYear ? t(`profile.errors.dobYear`) : undefined}
                                            touched={touched.dobYear}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Text
                                            name="memberId"
                                            label={t('pages.profile.insurance.memberID')}
                                            onChange={handleChange}
                                            errors={
                                                errors?.memberId
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('pages.profile.insurance.memberID')
                                                      })
                                                    : undefined
                                            }
                                            touched={touched.memberId}
                                            defaultValue={values.memberId}
                                        />
                                        <div className="tooltip-wrapper">
                                            <Tooltip tip={t('pages.profile.insurance.memberIDToolTip')} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-column align-items-center">
                                        {!!status && (
                                            <div className="text has-errors">
                                                <div className="text-errors">{getErrorFromStatus(status)}</div>
                                            </div>
                                        )}
                                        <Button
                                            disabled={isSubmitting}
                                            label={t('registration.submit')}
                                            variant="primary"
                                            type="submit"
                                            dataGAFormName={formName}
                                            dataGAFormStep="Step1"
                                            dataGAFormStepName="Account"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column align-items-center mt-3">
                    <WorkflowSteps id="registration-page" activeStepName="Account" activeStep={1} numberOfSteps={3} />
                </Col>
            </Row>
        </div>
    );
};

export default RegistrationForm;
