import React, { ReactElement } from 'react';

import Select from 'ui-kit/select/select';

import { FormSelectProps } from 'ui-kit/form-select/form-select.props';
import { SelectChangeEvent } from 'ui-kit/select/select.props';
import { SelectOptionValue } from 'types/select';

const FormSelect = <T extends SelectOptionValue>(props: FormSelectProps<T>): ReactElement => {
    const { form, field, onSelectionChanged, ...rest } = props;

    const handleChange = ({ key, option }: SelectChangeEvent<T>): void => {
        const { value } = option;
        if (form && form.setFieldValue) form.setFieldValue(field.name, value);
        if (onSelectionChanged) onSelectionChanged({ key, option });
    };
    return <Select<T> onChange={handleChange} {...rest} />;
};

export default FormSelect;
