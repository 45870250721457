import React, { ReactElement } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';

import { accountRegisterRoutine } from 'state/account/account.routines';

import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import RegistrationForm, { RegistrationFormValues } from 'components/registration-form/registration-form.component';
import CtaStack from 'ui-kit/cta-stack/cta-stack';
import { FormikHelpers } from 'formik';

const GetStarted = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const imageData = useStaticQuery(graphql`
        query {
            blueSkyBackground: file(relativePath: { eq: "assets/images/feather-background.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    const onSubmit = (
        values: Partial<RegistrationFormValues>,
        helpers: FormikHelpers<Partial<RegistrationFormValues>>
    ) => {
        dispatch(
            accountRegisterRoutine.trigger({
                formValues: values,
                formHelpers: helpers
            })
        );
    };

    return (
        <WorkflowLayout
            backgroundImage={imageData.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('registration.eyebrowText') }}
        >
            <WorkflowLayoutFormWrapper
                eyebrowText={t('registration.eyebrowText')}
                title={t('registration.profileTitle')}
            >
                <CtaStack to="/sign-in" label={t('registration.signIn')} />
                <RegistrationForm onSubmit={onSubmit} />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default GetStarted;
