import * as yup from 'yup';

export const REGISTRATION_SCHEMA = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    dobMonth: yup.string().required(),
    dobDay: yup.string().required(),
    dobYear: yup.string().required(),
    memberId: yup.string().required()
});

export const REGISTRATION_SECURITY_QUESTION_ANSWER_SCHEMA = yup.object().shape({
    question1: yup.string().required(),
    question2: yup.string().required(),
    question3: yup.string().required(),
    answer1: yup.string().required(),
    answer2: yup.string().required(),
    answer3: yup.string().required()
});
