import React from 'react';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';

import { WorkflowLayoutFormWrapperProps } from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.props';

import './workflow-layout-form-wrapper.style.scss';

const WorkflowLayoutFormWrapper = ({
    children,
    className,
    eyebrowText,
    title,
    icon
}: WorkflowLayoutFormWrapperProps) => {
    const classes = classNames('workflow-layout-form-wrapper', className);

    return (
        <div className={classes}>
            <Container fluid>
                <Row>
                    <Col className="workflow-layout-form-wrapper-header d-flex flex-column justify-content-center align-items-center">
                        {icon}
                        {eyebrowText && <h5>{eyebrowText}</h5>}
                        <h1 className="h2">{title}</h1>
                        <div className="spacer" />
                    </Col>
                </Row>
                <Row>
                    <Col className="workflow-layout-form-wrapper-content">{children}</Col>
                </Row>
            </Container>
        </div>
    );
};

export default WorkflowLayoutFormWrapper;
